import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import './Blog.css';


// import hljs moved to real time when needed because timming issue causing css to not being followed 

// import hljs from 'highlight.js';
// import 'highlight.js/styles/github.css'; // You can choose other styles
// import 'highlight.js/styles/monokai-sublime.css'; // Example of a dark theme
// import './github-darkkkk.css'; // Example of a dark theme
// import 'highlight.js/styles/github-dark.css'; // You can choose other styles

// marked.setOptions({
//     highlight: function (code, language) {
//     // console.log("ok")
//     console.log("Highlighting language: ", language); // Log the detected language
//     const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
//     return hljs.highlight(validLanguage, code).value;
//     }
// });





export const BlogHeader = ({ postPath }) => {
    // const [postContent, setPostContent] = useState('');
    const [frontMatter, setFrontMatter] = useState({});

    useEffect(() => {
        fetch(postPath)
        .then(response => response.text())
        .then(text => {
            const frontMatterText = extractFrontMatter(text);
            if (frontMatterText) {
            setFrontMatter(parseFrontMatter(frontMatterText));
            // Remove the front matter from the markdown content
            // setPostContent(text.replace(/---\n[\s\S]+?\n---/, ''));
            } 
            // else {
            // setPostContent(text);
            // }
        })
        .catch(error => console.error('Error fetching the markdown file: ', error));
    }, [postPath]);

    return (
    <div>
        <div style={{ 
            height:'10vw', top:'0px', position:'relative', width:'25vw',textAlign: 'left',
            background: 'linear-gradient(to bottom, rgba(0, 25, 25, 1), rgba(0, 0, 0, 0))',
            padding: '0px', // Add some padding to ensure the background is visible around the text
        }}>
            <div style={{ color: 'rgb(10, 150, 255)', fontSize:'22px', top:'20px', left: '20px', position: 'relative'}}>{frontMatter.title}</div>
            <div style={{  width: '20vw', color: 'rgb(100, 205, 255)', fontSize:'16px', top:'30px', left: '20px', position: 'relative'}}>{frontMatter.summary}</div>
        </div>

    </div>

    );
};




export const BlogPost = ({ postPath }) => {
    const [postContent, setPostContent] = useState('');
    const [frontMatter, setFrontMatter] = useState({});

    useEffect(() => {
        fetch(postPath)
        .then(response => response.text())
        .then(text => {
            const frontMatterText = extractFrontMatter(text);
            if (frontMatterText) {
            setFrontMatter(parseFrontMatter(frontMatterText));
            // Remove the front matter from the markdown content
            setPostContent(text.replace(/---\n[\s\S]+?\n---/, ''));
            } else {
            setPostContent(text);
            }
        })
        .catch(error => console.error('Error fetching the markdown file: ', error));
    }, [postPath]);



    useEffect(() => {
        import('./github-dark.css').then(() => {
          import('highlight.js').then(hljs => {
            document.querySelectorAll('pre code').forEach((block) => {
              hljs.default.highlightBlock(block);
            });
          });
        });
      }, [postContent]);
      
    // useEffect(() => {
    //     document.querySelectorAll('pre code').forEach((block) => {
    //         hljs.highlightBlock(block);
    //     });
    // }, [postContent]); // Re-run syntax highlighting when postContent changes


    const getMarkdownText = () => {
        // var rawMarkup = marked(postContent, {    sanitize: true });
        var rawMarkup = marked(postContent);
        // console.log(rawMarkup)
        // return { __html: marked(postContent) };
        return { __html: rawMarkup };
        }


    return (

        // <TestHighlight/>
        <div style={{backgroundColor: 'rgb(25, 25, 25)' }}>
            {/* boxSizing:'border-box' */}
            {/* ,  minHeight: '100vh' */}
            {/* , overflowX:'hidden', overflowY:'auto' */}
            {/* <div style={{ margin:0, padding:0}}> */}
                {/* <div style={{margin:0, padding:0, fontSize:'20px',  width: '60vw',textAlign: 'left', left: '20vw', right:'20vw', top: '5vh', position: 'relative'}}> */}
                <div style ={{margin:0, padding:0, position:'absolute', width:'18vw', left:'0vw',top:'15vh'}}><MarkdownSidebar markdown={postContent} /></div>
                <div style={{margin:0, padding:0, fontSize:'20px',  width: '60vw',textAlign: 'left', left: '20vw', right:'20vw', top: '0vh', position: 'relative', color:'rgb(250, 250, 250)'}}>
                    <div dangerouslySetInnerHTML={getMarkdownText()} />
                    {/* <MarkdownRenderer2 markdownText={"```js\nconsole.log('Hello, world!');\n```"} /> */}
                </div>
            {/* </div> */}
        </div>
    );
};





// export const TestHighlight = () => {
//   useEffect(() => {
//     hljs.highlightAll();
//   }, []);

//   return (
//     <pre><code className="language-javascript">
//       {`function test() {
//         console.log("Highlight.js test");
//       }`}
//     </code></pre>
//   );
// };




export const extractFrontMatter = markdown => {
    const match = /---\n([\s\S]+?)\n---/.exec(markdown);
    return match ? match[1] : null;
};

export const parseFrontMatter = frontMatter => {
    const lines = frontMatter.split('\n');
    return lines.reduce((acc, line) => {
        const [key, value] = line.split(':').map(s => s.trim());
        if (key && value) {
        acc[key] = value;
        }
        return acc;
    }, {});
};

export const MarkdownRenderer = ({ markdownText }) => {
    const getMarkdownText = () => {
        var rawMarkup = marked(markdownText);
        // var rawMarkup = marked(markdownText, { sanitize: true });
            return { __html: rawMarkup };
        }     
    return <div dangerouslySetInnerHTML={getMarkdownText()} />;
  }
  


// export const MarkdownSidebar = ({ markdown }) => {
//     const [headers, setHeaders] = useState([]);

//     useEffect(() => {
//         const lines = markdown.split('\n');
//         const headerRegex = /^(#+)\s+(.*)/;
//         const extractedHeaders = lines
//         .map(line => {
//             const match = line.match(headerRegex);
//             if (match) {
//             const depth = match[1].length;
//             const text = match[2].trim();
//             const id = text.toLowerCase().replace(/\s+/g, '-');
//             return { depth, text, id };
//             }
//             return null;
//         })
//         .filter(header => header !== null);

//         setHeaders(extractedHeaders);
//     }, [markdown]);

//     const handleNavigation = (id) => {
//         const element = document.getElementById(id);
//         if (element) {
//         element.scrollIntoView({ behavior: 'smooth' });
//         }
//     };

//     return (
//         <aside>
//         <ul>
//             {headers.map(header => (
//             <li key={header.id} style={{ marginLeft: `${header.depth - 1}em` }}>
//                 <button onClick={() => handleNavigation(header.id)}>
//                 {header.text}
//                 </button>
//             </li>
//             ))}
//         </ul>
//         </aside>
//     );
// };  



export const MarkdownSidebar = ({ markdown }) => {
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        const lines = markdown.replace(/```[\s\S]+?\n```/, '').split('\n');
        const headerRegex = /^(#+)\s+(.*)/;
        const extractedHeaders = lines
        .map(line => {
            const match = line.match(headerRegex);
            if (match) {
                const depth = match[1].length;
                const text = match[2].replace(/\*/g,'').trim();
                const id = text.toLowerCase().replace(/\s+/g, '-');
                // return { depth, text, id };
                return { depth, text, id };
            }
            return null;
        })
        .filter(header => header !== null);

        setHeaders(extractedHeaders);
    }, [markdown]);

    return (
        <aside>
            <ul>
                {headers.map(header => (
                    <li key={header.id} style={{ 
                        marginLeft: `${header.depth - 2}em`,
                        fontSize: '18px', textAlign: 'left',lineHeight:'1.6',
                        color: 'rgb(25, 250, 250)',listStyleType: 'none' // Add this line to remove bullet points
                    }}>
                        {header.text}
                    </li>
                ))}
            </ul>
        </aside>
    );
};



















// export  const copyToClipboard = text => {
//     navigator.clipboard.writeText(text).then(() => {
//       // Handle successful copy (e.g., show a message)
//     }, () => {
//       // Handle error (e.g., show an error message)
//     });
//   };
  


//  export const MarkdownRenderer2 = ({ markdownText }) => {
//     const renderedHtml = marked(markdownText);
  
//     const handleCopyClick = (code) => {
//       copyToClipboard(code);
//     };
  
//     // Function to add a copy button to each code block
//     const addCopyButtons = (html) => {
//       const parser = new DOMParser();
//       const doc = parser.parseFromString(html, 'text/html');
//       const codeBlocks = doc.querySelectorAll('pre code');
  
//       codeBlocks.forEach(block => {
//         const button = document.createElement('button');
//         button.textContent = 'Copy';
//         button.onclick = () => handleCopyClick(block.textContent);
//         block.parentNode.insertBefore(button, block);
//       });
  
//       return doc.body.innerHTML;
//     };
  
//     return <div dangerouslySetInnerHTML={{ __html: addCopyButtons(renderedHtml) }} />;
//   };
  