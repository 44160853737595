import React, { createContext, useState, useEffect } from 'react';
export const Context1 = createContext();
export const Context1Provider = ({ children }) => {
  const [TechnologyDropdownEnable, setTechnologyDropdownEnable] = useState(false);
  const [ChatOptionEnable, setChatOptionEnable] = useState(true);
  // const [ChatPageEnable, setChatPageEnable] = useState(false);
  // const [CurrentlySelectedCharacter, setCurrentlySelectedCharacter] = useState(1);
  // const [currentProfileIndex, setcurrentProfileIndex]=useState(0);
  
  // ... other states
  return (
    <Context1.Provider value={{ 
        TechnologyDropdownEnable, setTechnologyDropdownEnable,
        ChatOptionEnable,setChatOptionEnable,
        // ChatPageEnable, setChatPageEnable,
        // CurrentlySelectedCharacter, setCurrentlySelectedCharacter,
        // currentProfileIndex, setcurrentProfileIndex
        }}>
      {children}
    </Context1.Provider>
  );
};