// import React, { useState, useEffect } from "react";

//  function AudioController() {
//     const [isRecording, setIsRecording] = useState(false);
//     const [audioData, setAudioData] = useState([]);

//     const onDataReceived = (data) => {
//         setAudioData((prevData) => [...prevData, data]);
//     };

//     const toggleRecording = () => {
//         setIsRecording((prev) => !prev);
//     };

//     const playAudio = () => {
//         const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
//         const buffer = audioCtx.createBuffer(1, audioData.length * 1024, audioCtx.sampleRate);
//         const source = audioCtx.createBufferSource();
//         const channelData = buffer.getChannelData(0);

//         audioData.forEach((chunk, index) => {
//         channelData.set(new Float32Array(chunk), index * 1024);
//         });

//         source.buffer = buffer;
//         source.connect(audioCtx.destination);
//         source.start();
//     };

//     return (
//     <div>
//         <h1 style={{color:'white'}}>Audio Controller</h1>
//         <button onClick={toggleRecording}>
//         {isRecording ? "Stop Recording" : "Start Recording"}
//         </button>

//         {isRecording ? (
//         <AudioRecorder onDataReceived={onDataReceived} />
//         ) : null}

//         <button onClick={playAudio} disabled={!audioData.length}>
//         Play Audio
//         </button>
//     </div>
//       );
// }

// export default AudioController










// const useAudioRecorder = (audioWebSocket) => {
//     useEffect(() => {
//         const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

//         navigator.mediaDevices.getUserMedia({ audio: true })
//         .then((stream) => {
//             const source = audioCtx.createMediaStreamSource(stream);
//             const processor = audioCtx.createScriptProcessor(1024, 1, 1);

//             processor.onaudioprocess = (e) => {
//             const audioData = e.inputBuffer.getChannelData(0);
//             audioWebSocket.sendAudioData(audioData.buffer); // Use the API to send audio data
//             };

//             source.connect(processor);
//             processor.connect(audioCtx.destination);
//         })
//         .catch((err) => {
//             console.error("Microphone access denied: ", err);
//         });

//         return () => {
//         if (audioCtx) {
//             audioCtx.close();
//         }
//         };
//     }, [audioWebSocket]);
//     };




// function AudioRecorder({ onDataReceived }) {
//     const audioWebSocket = new AudioWebSocket("ws://your-backend-server.com", onDataReceived);
//     // useAudioRecorder(audioWebSocket);

//     useEffect(() => {
//         return () => {
//         audioWebSocket.close(); // Close WebSocket connection when component unmounts
//         };
//     }, [audioWebSocket]);
   
//       return null;
// }









// class AudioWebSocket {
//     constructor(url, onDataReceived) {
//         this.ws = new WebSocket(url);
//         this.onDataReceived = onDataReceived; // Callback to handle data from the server
//         this.initializeWebSocket();
//     }

//     initializeWebSocket() {
//         this.ws.onopen = () => {
//         console.log("WebSocket connection opened");
//         };

//         this.ws.onerror = (error) => {
//         console.error("WebSocket Error: ", error);
//         };

//         this.ws.onclose = () => {
//         // Implement WebSocket reconnection logic here
//         console.log("WebSocket disconnected, attempting to reconnect...");
//         setTimeout(() => {
//             this.ws = new WebSocket(this.ws.url);
//             this.initializeWebSocket();
//         }, 3000);
//         };

//         this.ws.onmessage = (event) => {
//             // Call the onDataReceived callback when data is received
//             this.onDataReceived(event.data);
//           };
//     }

//     sendAudioData(audioData) {
//         if (this.ws.readyState === WebSocket.OPEN) {
//         this.ws.send(audioData);
//         }
//     }

//     close() {
//         if (this.ws) {
//         this.ws.close();
//         }
//     }
// }





























import React, { useEffect, useState, useRef } from "react";





// Closes the WebSocket connection if it exists.

// Event Loop & Asynchronous Nature
// This code takes full advantage of JavaScript's event-driven, non-blocking nature. The event handlers (onopen, onerror, onclose) are defined as arrow functions and will execute asynchronously when the relevant events occur.

// Key Concepts
// WebSocket: A web communication protocol that provides full-duplex communication channels over a single TCP connection.

// Event-driven programming: The approach used here, where you define behaviors (functions) that should happen when certain events occur.

// Pseudo Workflow
// Instantiate AudioWebSocket with a WebSocket server URL.
// initializeWebSocket() method sets up event handlers.
// When the connection opens, onopen fires and logs to console.
// If an error occurs, onerror fires and logs the error.
// If the WebSocket closes, onclose fires, logs the event, and attempts reconnection.
// To send audio data, call sendAudioData().
// To manually close the WebSocket, call close().
// The WebSocket's readyState is checked before sending data to make sure the connection is open. If the state is not WebSocket.OPEN, the data is not sent.

// WebSocket Reconnection Logic
// The onclose event handler includes logic for reconnecting to the WebSocket. If the connection closes for some reason, the code waits for 3 seconds (setTimeout) before attempting to reconnect.

// That should give you a detailed understanding of how this JavaScript class works. Feel free to ask if you need further clarification on any part.
let singletonWS = null;
class AudioWebSocket {

    // Constructor is analogue to __init__ in python
    constructor(url) {
        //It seems the fact that <React.StrictMode> load the component twice suggest it wants any connection initialization to be done outside constructor
        // this.ws = new WebSocket(url);
        this.url=url;

        // this.sendAudioData = this.sendAudioData.bind(this);
        // this.close = this.close.bind(this);

        // this.ws =new WebSocket(this.url);
        // this.initializeWebSocket();
    }
    // Used to open  open and maintain websocket connction

    constructWS(){
        // if (!singletonWS) {
        //     singletonWS = new WebSocket(this.url); // This ensure there is only one WebSocket connection from client
        //     this.ws = singletonWS;
        //     this.initializeWebSocket();
        //     singletonWS=true; //redefine will make this.ws change too, making it unusable outside of initializeWebSocket
        // }
        if (!singletonWS){
            singletonWS=true;
            console.log("a")
            this.ws =new WebSocket(this.url);
            this.initializeWebSocket();
            this.ConnctionStatusShould="open"
        }


    }

    initializeWebSocket() {
        // try{
        console.log("attempt to establish Websocket connection")
        console.log (this.ws)
        this.ws.onopen = () => {
            console.log("WebSocket connection opened");
        };
        // }catch(error){
        //     console.log("Websocket Error",error)
        this.ws.onerror = (error) => {
            console.error("WebSocket Error: ", error);
        };
        // }
        this.ws.onclose = () => {
            // Implement WebSocket reconnection logic here
            
            setTimeout(() => {

                console.log("WebSocket disconnected, attempting to reconnect...");
                this.ws = new WebSocket(this.url);
                this.initializeWebSocket();

                // singletonWS = new WebSocket(this.url); // This ensure there is only one WebSocket connection from client
                // this.ws = singletonWS;
                // singletonWS=true;
                // this.initializeWebSocket();

                // this.ws.onopen = () => {
                //     console.log("WebSocket connection opened");
                // };
            }, 30000);
        };
    }
    sendAudioData(audioData) {
        if (this.ws.readyState === WebSocket.OPEN) {
            console.log("send chunk")
            
            this.ws.send(audioData);
        }
    }
    checkThis(){
        // console.log(this.ws)
        return(this.ws)
    }
    // close() {
    //     if (this.ws) {
    //         console.log("close websocket")
    //         this.ws.close();
    //     }
    // }

    destroy() {
        // Nullify WebSocket instance to indicate it's no longer in use
        this.ws = null;
        console.log('WebSocket destroyed');
      }
      
    close() {
        // console.log(this.ws)
        // if (this.ws.readyState === WebSocket.OPEN) {  
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {  
            console.log("close websocket");
            this.ws.close();
            this.destroy();
        }
    } 
}
  


  

const useAudioRecorder = (audioWebSocket) => {
    useEffect(() => {
    // AudioContextis  is Web Audio API provided by web browsers used for processing and synthesizing audio in web applications.
    // acts as an entry point into the web audio API and is used to create nodes, setup audio routing, and initialize audio processing.
    const audioCtx = new AudioContext();
    console.log("Load AudioContext")
    // Load the audio worklet processor, then is used This line starts by attempting to add an AudioWorklet module from the specified path. 
    // Since this operation is asynchronous, .then() is used to wait for it to complete. If it is successful, the function passed to .then() is executed.
    // AudioWorklet: We're now using AudioWorklet to perform audio processing. An AudioWorklet runs in a separate thread, allowing for more complex operations without affecting the main thread.
    // AudioWorkletNode: Replaces the ScriptProcessorNode. It's more flexible and doesn't run on the main thread, reducing the chance of glitches.
    // Worklet File: The audio-processor.js file contains the custom audio processing logic. It's registered to the audio context with audioCtx.audioWorklet.addModule().



    audioCtx.audioWorklet.addModule("audio-processor.js").then(() => {
        console.log("load audio-processor js")

        // the script tries to access the user's microphone.  and once permission is granted (or if the audio device is successfully accessed), the function in this .then() is executed.
        // The stream parameter represents a MediaStream object that contains the audio (or video, or both) stream data from a user's media device, such as a microphone or camera.
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            console.log("Stream object:", stream);  // Check the stream object

            const source = audioCtx.createMediaStreamSource(stream);
            const audioProcessor = new AudioWorkletNode(audioCtx, "audio-processor");
            console.log("A")
            
            audioProcessor.port.onmessage = (e) => {
                // console.log("c")
                // console.log(e.data.audioBuffer)

                // audioWebSocket.sendAudioData(e.data.audioBuffer);
            };
            console.log("B")

            source.connect(audioProcessor);
            audioProcessor.connect(audioCtx.destination);

        }).catch((err) => {
            console.error("Microphone access denied: ", err);
        });

    }).catch((err) => {
        console.error("Failed to load audio worklet:", err);
    });
    return () => {
        if (audioCtx) {
            audioCtx.close();
        }
    };
    }, [audioWebSocket]);
};
  




  
function AudioRecorder() {

    const audioWebSocket = new AudioWebSocket("wss://server.entrosystem.com");

    // useAudioRecorder(audioWebSocket);


    // The code you place in the body of the useEffect function will run after the component mounts and renders for the first time.
    // The function that you return from useEffect will run when the component unmounts.
    useEffect(() => {
        // This code will run after the component is initially rendered, if there is any
        console.log("mounted")
        audioWebSocket.initializeWebSocket()
        return () => {
            // This code will run when the component is removed from the DOM (i.e., "unmounted")
            console.log("unmounted")
            audioWebSocket.close(); // Close WebSocket connection when component unmounts

        };
    }, []);//The empty dependency array [] ensures that this behavior occurs only once
    // }, [audioWebSocket]);

    return (
        <div>
            <h1>Audio Recorder</h1>
        </div>
    );
}


// function SendAudio (){
//     const [AudioRecorderEnable, setAudioRecorderEnable] =useState(false);
//     const RecordAndSendAudio = () => {
//         // setAudioRecorderEnable(!AudioRecorderEnable)
//         setAudioRecorderEnable((prevEnable) => !prevEnable);
//         console.log(AudioRecorderEnable)
//     }
//     return (
//         <div>
//             { AudioRecorderEnable && < AudioRecorder />}
//             <button onClick={RecordAndSendAudio}>Send Audio</button>
//             {/* <button onClick={RecordAndSendAudio}>Send Audio</button> */}
//         </div>
//     )
// }


// function SendAudio() {
//     const [AudioRecorderEnable, setAudioRecorderEnable] = useState(false);

//     console.log("SendAudio")

//     const RecordAndSendAudio = () => {
//         // Toggle the state
//         setAudioRecorderEnable((prevEnable) => !prevEnable);
//     };

//     // Log the state when it changes
//     useEffect(() => {
//         console.log('AudioRecorderEnable:', AudioRecorderEnable);
//     }, [AudioRecorderEnable]);

//     return (
//         <div>
//             {AudioRecorderEnable && <AudioRecorder />}
//             <button onClick={RecordAndSendAudio}>Send Audio</button>
//         </div>
//     );
// }



function SendAudio() {
    // const audioWebSocket = new AudioWebSocket("wss://server.entrosystem.com");
    const audioWebSocketRef = useRef(null);

    // const [audioWebSocket] = useState(() => new AudioWebSocket("wss://server.entrosystem.com"));
    const [shouldMountAudioRecorder, setShouldMountAudioRecorder] = useState(false);

    if (audioWebSocketRef.current === null) {
        audioWebSocketRef.current = new AudioWebSocket("wss://server.entrosystem.com");
    }

    const handleButtonClick = () => {
        if (shouldMountAudioRecorder==false){
            console.log("open")
            audioWebSocketRef.current.constructWS();

            // audioWebSocket.constructWS();
        }else{
            console.log("close")
            audioWebSocketRef.current.close();
            // audioWebSocketRef.current=null;
            // audioWebSocket.checkThis();
            // audioWebSocket.close();
        }
        setShouldMountAudioRecorder(prevState => !prevState);
    };
    const WebSocketStatus = () => {
        console.log(audioWebSocketRef.current.checkThis())
    }

    useEffect(() => {
        console.log('AudioRecorder mounted');
        return () => {
            console.log('AudioRecorder will unmount');
        };
    }, []);

    return (
        <div>
            {/* {shouldMountAudioRecorder && <AudioRecorder />}  */}
            <button onClick={handleButtonClick}>
                {shouldMountAudioRecorder ? 'Stop Audio' : 'Send Audio'}
            </button>
            <button onClick={WebSocketStatus}>
                {"Status"}
            </button>
        </div>
    );
}


export default SendAudio