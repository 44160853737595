import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Context1Provider } from './Context1';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // React.StrictMode is a wrapper component that checks for potential problems in the React components inside it. 
  // It performs additional checks and warnings for its descendants in the development build, without any impact on the production build.
  // It causes component to mount twice

  <React.StrictMode>
    <Context1Provider>
      <App />
    </Context1Provider>
  </React.StrictMode>

    // <Context1Provider>
    //   <App />
    // </Context1Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
